import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './UserMenu.css';
import { useAuth0 } from '@auth0/auth0-react';

const UserMenu = ({ userInitial }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth0();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    handleClose();
  };

  return (
    <div className="user-menu-container">
      <IconButton onClick={handleClick} className="user-icon-button">
        <Avatar className="user-avatar">{userInitial}</Avatar>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="user-menu"
      >
        <MenuItem className="menu-item" disableRipple onClick={handleClose}>Settings</MenuItem>
        <MenuItem className="menu-item" disableRipple onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;