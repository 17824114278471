import React, { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, description }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <div className="confirm-dialog-modal-backdrop">
      <div className="confirm-dialog-modal-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="confirm-dialog-buttons">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={handleConfirm} className="confirm-button" disabled={isLoading}>
            {isLoading ? (
              <ThreeDots color="#fff" height={10} width={24} />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;