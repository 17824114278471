import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import './CampaignBuilder.css';
import { FaSave, FaEdit, FaTrashAlt, FaArrowLeft } from 'react-icons/fa'; 
import consumerResearchImg from '../assets/consumer_research.svg';
import adTestingImg from '../assets/ad_test.svg';
import conceptTestingImg from '../assets/concept_testing.svg';
import consumerFeedbackImg from '../assets/consumer_feedback.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import { ThreeDots } from 'react-loader-spinner';
import mixpanel from 'mixpanel-browser';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const CampaignBuilder = ({ onClose, onCampaignCreated, campaign }, ref  ) => {
  const [step, setStep] = useState(campaign ? campaign.current_step : 0);
  const [selectedBlock, setSelectedBlock] = useState(null); 
  const steps = ['Research Type', 'Objective', 'Concept', 'Questions', 'Who', 'Review', 'Test'];

  const [questions, setQuestions] = useState(campaign && Array.isArray(campaign.questions) ? campaign.questions.map((q, index) => ({
    id: `question-${index + 1}`,
    question: q.question,
    followup: q.followup
  })) : []);
  
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [editIndex, setEditIndex] = useState(null); 
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false); 
  const [newQuestion, setNewQuestion] = useState('');
  const [audienceOption, setAudienceOption] = useState('own'); 
  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);

  const [researchObjective, setResearchObjective] = useState(campaign ? campaign.business_objective : '');
  const [conceptDescription, setConceptDescription] = useState(campaign ? campaign.context : '');
  const [businessUsage, setBusinessUsage] = useState(campaign ? campaign.business_usage : '');
  const questionsGeneratedRef = useRef(false);
  const [selectedCountry, setSelectedCountry] = useState([{ value: 'US', label: 'US' }]);
  const [selectedState, setSelectedState] = useState([{ value: 'All', label: 'All' }]);
  const [selectedEducation, setSelectedEducation] = useState([{ value: 'Graduate', label: 'Graduate' }]);
  const [selectedOccupation, setSelectedOccupation] = useState([{ value: 'Full-time', label: 'Full-time' }]);
  const [selectedIncomeRange, setSelectedIncomeRange] = useState([{ value: '50,000-100,000', label: '50,000-100,000' }]);

  const countryOptions = [{ value: 'US', label: 'US' }, { value: 'Canada', label: 'Canada' }];
  const stateOptions = [{ value: 'All', label: 'All' }, { value: 'California', label: 'California' }];
  const educationOptions = [{ value: 'Graduate', label: 'Graduate' }, { value: 'Undergraduate', label: 'Undergraduate' }];
  const occupationOptions = [{ value: 'Full-time', label: 'Full-time' }, { value: 'Part-time', label: 'Part-time' }];
  const incomeRangeOptions = [{ value: '50,000-100,000', label: '50,000-100,000' }, { value: '100,000-150,000', label: '100,000-150,000' }];

  const [campaignId, setCampaignId] = useState(campaign ? campaign.id : null);
  const [campaignName, setCampaignName] = useState(campaign ? campaign.name : '');
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [initialObjective, setInitialObjective] = useState('');
  const [initialQuestions, setInitialQuestions] = useState(campaign ? campaign.questions : []);
  const [testLink, setTestLink] = useState(campaign ? campaign.test_link : null);
  const [liveLink, setLiveLink] = useState(campaign ? campaign.live_link : null);
  const [initialContext, setInitialContext] = useState('');
  const [navigatedBack, setNavigatedBack] = useState(false);
  const researchObjectiveRef = useRef(null);
  const conceptDescriptionRef = useRef(null);

  useEffect(() => {
    mixpanel.track('CampaignBuilderViewed');
  }, []);

  useEffect(() => {
    if (campaign) {
      if (step === 1 && researchObjectiveRef.current) {
        setInitialObjective(researchObjectiveRef.current.value || '');
      }
      if (step === 2 && conceptDescriptionRef.current) {
        setInitialContext(conceptDescriptionRef.current.value || '');
      }
    }
  }, [campaign, step]);

  useEffect(() => {
    if (step === 3 && !navigatedBack) {
      checkAndGenerateQuestions();
    }
  }, [step, navigatedBack]);

  useEffect(() => {
    if (campaign) {
      setCampaignId(campaign.id);
      setStep(campaign.current_step || 0);
      setCampaignName(campaign.name || '');

      setResearchObjective(campaign.business_objective || '');
      setConceptDescription(campaign.context || '');
      setInitialObjective(campaign.business_objective || '');
      setInitialContext(campaign.context || '');
      
      const typeToBlockMap = {
        'consumer_research': 1,
        'ad_testing': 2,
        'concept_testing': 3,
        'consumer_feedback': 4
      };
      setSelectedBlock(typeToBlockMap[campaign.type] || null);
      
      if (campaign?.questions) {
        setQuestions(campaign.questions.map((q, index) => ({
          id: `question-${index + 1}`,
          question: q.question,
          followup: q.followup
        })));
      }
      if (campaign.target_audience) {
        setSelectedCountry(campaign.target_audience.country?.map(c => ({ value: c, label: c })) || []);
        setSelectedState(campaign.target_audience.state?.map(s => ({ value: s, label: s })) || []);
        setSelectedEducation(campaign.target_audience.education?.map(e => ({ value: e, label: e })) || []);
        setSelectedOccupation(campaign.target_audience.occupation?.map(o => ({ value: o, label: o })) || []);
        setSelectedIncomeRange(campaign.target_audience.income_range?.map(i => ({ value: i, label: i })) || []);
      }
    } else {
      setCampaignId(null);
      setStep(0);
      setCampaignName('');
      setSelectedBlock(null);
      setQuestions([]);
      setSelectedCountry([{ value: 'US', label: 'US' }]);
      setSelectedState([{ value: 'All', label: 'All' }]);
      setSelectedEducation([{ value: 'Graduate', label: 'Graduate' }]);
      setSelectedOccupation([{ value: 'Full-time', label: 'Full-time' }]);
      setSelectedIncomeRange([{ value: '50,000-100,000', label: '50,000-100,000' }]);
  }
  }, [campaign]);

  const hasCampaignChanged = () => {
    const currentObjective = researchObjective;
    const currentContext = conceptDescription;
    const currentQuestions = questions;

    const objectiveChanged = currentObjective !== initialObjective;
    const contextChanged = currentContext !== initialContext;
    const questionsChanged = JSON.stringify(currentQuestions) !== JSON.stringify(initialQuestions);

    return objectiveChanged || contextChanged || questionsChanged;
};

  const generateCampaignQuestions = async (instruction) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const payload = instruction ? { instruction } : {};
  
      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/generate-questions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to generate questions: ${errorText}`);
      }
  
      const data = await response.json();
  
      return data.questions;
    } catch (error) {
      console.error('Error generating questions:', error);
      throw error;
    }
  };
  
  
  const handleAddFilter = (filter, value) => {
    switch (filter) {
      case 'country':
        setSelectedCountry([...selectedCountry, value]);
        break;
      case 'state':
        setSelectedState([...selectedState, value]);
        break;
      case 'education':
        setSelectedEducation([...selectedEducation, value]);
        break;
      case 'occupation':
        setSelectedOccupation([...selectedOccupation, value]);
        break;
      case 'incomeRange':
        setSelectedIncomeRange([...selectedIncomeRange, value]);
        break;
      default:
        break;
    }
  };

  const handleRemoveFilter = (filter, value) => {
    switch (filter) {
      case 'country':
        setSelectedCountry(selectedCountry.filter(item => item !== value));
        break;
      case 'state':
        setSelectedState(selectedState.filter(item => item !== value));
        break;
      case 'education':
        setSelectedEducation(selectedEducation.filter(item => item !== value));
        break;
      case 'occupation':
        setSelectedOccupation(selectedOccupation.filter(item => item !== value));
        break;
      case 'incomeRange':
        setSelectedIncomeRange(selectedIncomeRange.filter(item => item !== value));
        break;
      default:
        break;
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  const continueStep = async () => {
    setLoading(true); 
    setNavigatedBack(false);
    
    await saveCurrentStep();
    
    if (step === 5) {
      try {
          const campaignChanged = hasCampaignChanged();
          
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
  
          if (testLink && campaignChanged) {
              
              const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${testLink.id}`, {
                  method: 'PUT',
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'X-ID-Token': idToken,
                      'Content-Type': 'application/json'
                  }
                  
              });
  
              if (!response.ok) {
                  throw new Error('Failed to update the interview');
              }
  
              const updatedInterview = await response.json();
              setTestLink(updatedInterview);
          } else if (!testLink) {
              
              const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
                  method: 'POST',
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'X-ID-Token': idToken,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      interview_type: "test"  
                  })
              });
  
              if (!response.ok) {
                  throw new Error('Failed to create the interview');
              }
  
              const newInterview = await response.json();
              
              setTestLink(newInterview.id);
          }
      } catch (error) {
          console.error('Error during interview API call:', error);
      }
  }
  
  if (step === 6) {

    try {
        const campaignChanged = hasCampaignChanged();

        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;

        if (liveLink && campaignChanged) {
            
            const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${liveLink.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'X-ID-Token': idToken,
                    'Content-Type': 'application/json'
                }
                
            });

            if (!response.ok) {
                throw new Error('Failed to update the live interview');
            }

            const updatedLiveInterview = await response.json();
            setLiveLink(updatedLiveInterview);  
        } else if (!liveLink) {
            
            const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'X-ID-Token': idToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    interview_type: "live" 
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create the live interview');
            }

            const newLiveInterview = await response.json();
            
            setLiveLink(newLiveInterview.id);
        }
    } catch (error) {
        console.error('Error during live interview API call:', error);
    }
}

if (step < steps.length) {
  let nextStep = step + 1;

  if (nextStep === 2 && (selectedBlock === 1 || selectedBlock === 4)) {
      nextStep = 3;
  }


      if (nextStep !== 3) {
        questionsGeneratedRef.current = false;
      }
  

  setStep(nextStep);
  if (nextStep === steps.length) {
      onCampaignCreated();
  }
}

    setLoading(false); 
};

  

  const createOrUpdateCampaign = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const url = campaignId && campaignId != 'null'
      ? `https://dashboard-api.echovane.com/api/campaigns/${campaignId}`
      : 'https://dashboard-api.echovane.com/api/campaigns';

      const method = campaignId ? 'PUT' : 'POST';


      const campaignData = {
        name: campaignName || 'Untitled Campaign',
        type: selectedBlock ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] : null,
        business_objective: document.getElementById('researchObjective')?.value || '',
        context: document.getElementById('conceptDescription')?.value || '',
        status: 'Draft',
        current_step: step
      };
  

      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(campaignData)
      });
  

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create/update campaign: ${errorText}`);
      }

      const data = await response.json();
      setCampaignId(data.id);
      return data;
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
      throw error;
    }
  };

  const saveCurrentStep = async () => {
    let campaignData = {};

    switch (step) {
        case 0:
            const researchType = selectedBlock 
                ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1] 
                : null;

            campaignData = {
                name: campaignName,
                type: researchType,
                current_step: step
            };

            if (campaignId) {
                await updateCampaign(campaignData);
            } else {
                campaignData = await createOrUpdateCampaign();
            }
            break;

        case 1:
            campaignData = {
                business_objective: document.getElementById('researchObjective').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;

        case 2:
            campaignData = {
                context: document.getElementById('conceptDescription').value,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;

        case 3:
            campaignData = {
              questions: questions.map(q => ({
                  question: q.question,
                  followup: q.followup
              })),
              name: campaignName,
              current_step: step
          };

          await updateCampaign(campaignData);
          break;

        case 4:
            const targetData = audienceOption === 'recruit' ? {
                country: selectedCountry.map(c => c.value),
                state: selectedState.map(s => s.value),
                education: selectedEducation.map(e => e.value),
                occupation: selectedOccupation.map(o => o.value),
                income_range: selectedIncomeRange.map(i => i.value)
            } : null;

            campaignData = {
                audience_option: audienceOption,
                target_audience: targetData,
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;

        default:
            campaignData = {
                name: campaignName,
                current_step: step
            };

            await updateCampaign(campaignData);
            break;
    }
};

const checkAndGenerateQuestions = () => {
  const objectiveChanged = researchObjective !== initialObjective;
  const contextChanged = conceptDescription !== initialContext;


  if (objectiveChanged || contextChanged) {
    setIsGeneratingQuestions(true);
    
    // Return a promise that resolves immediately
    return new Promise((resolve) => {
      // Use setTimeout to move the operation to the next event loop tick
      setTimeout(async () => {
        try {
          const generatedQuestions = await generateCampaignQuestions();
          const newQuestions = generatedQuestions.map((q, index) => ({
            id: `question-${index + 1}`,
            question: q.question,
            followup: q.followup
          }));
          
          // Update questions in the state
          setQuestions(newQuestions);

          // Update campaign data
          await updateCampaign({
            questions: newQuestions,
            business_objective: researchObjective,
            context: conceptDescription
          });

          // Update initial values
          setInitialObjective(researchObjective);
          setInitialContext(conceptDescription);
        } catch (error) {
          console.error("Error generating questions:", error);
        } finally {
          setIsGeneratingQuestions(false);
        }
      }, 0);

      // Resolve the promise immediately
      resolve();
    });
  } else {
    // If no changes, return a resolved promise
    return Promise.resolve();
  }
};


const updateCampaign = async (campaignData) => {
    try {
        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;


        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'X-ID-Token': idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(campaignData)
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(`Failed to update campaign: ${response.status} ${response.statusText}`);
        }

        const updatedCampaign = await response.json();
        return updatedCampaign;
    } catch (error) {
        console.error('Error updating campaign:', error);
        throw error;
    }
};



const handleQuestionChange = (index, field, value) => {
  const updatedQuestions = questions.map((q, i) =>
    i === index ? { ...q, [field]: value } : q
  );
  setQuestions(updatedQuestions);
};



    const handleOnDragEnd = (result) => {
      
      if (!result.destination) {
        
        return;
      }

      const items = Array.from(questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      
      setQuestions([...items]);
    };
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


const customFilterOption = (option, rawInput) => {
  const words = rawInput.split(' ');
  const fullString = option.label.toLowerCase();
  return words.reduce((acc, cur) => {
    return acc && fullString.includes(cur.toLowerCase());
  }, true);
};

useImperativeHandle(ref, () => ({
  checkAndGenerateQuestions
}));

const handleAddQuestion = () => {
  if (newQuestion.trim() !== '') {
    const newQuestionObj = {
      id: `question-${questions.length + 1}`,
      question: newQuestion,
      followup: ''
    };
    setQuestions([...questions, newQuestionObj]);
    setNewQuestion('');
  }
};

const handleDeleteQuestion = (index) => {
  const updatedQuestions = questions.filter((_, i) => i !== index);
  setQuestions(updatedQuestions);
};

const handleEditQuestion = (index) => {
  setEditIndex(index);
};

const handleResearchObjectiveChange = (e) => setResearchObjective(e.target.value);
const handleConceptDescriptionChange = (e) => setConceptDescription(e.target.value);
const handleBusinessUsageChange = (e) => setBusinessUsage(e.target.value);

const handleStepChange = (newStep, navigatedBack) => {

  if (navigatedBack) {
    

    if ( step === 3) {
      
      if (selectedBlock === 1 || selectedBlock === 4) {
        setStep(1); 
      } else {
        setStep(newStep);
      }
    }
  else {
      setStep(newStep);
    }
  }
  else {
    setStep(newStep);
  }
};

const handleSave = () => {
  saveCurrentStep();
  checkAndGenerateQuestions();
  onClose();
};


  const handleSaveQuestion = () => {
    setEditIndex(null);
  };

  return (
    <div className="builder-container" onClick={(e) => e.stopPropagation()}>
      <div className="progress-container">
      <div className="progress-indicator">
            {steps.filter((_, index) => 
              !(selectedBlock === 1 || selectedBlock === 4) || index !== 2
            ).map((stepName, index) => {
              // Adjust the step number for comparison
              const adjustedStep = (selectedBlock === 1 || selectedBlock === 4) && index > 1 ? index + 1 : index;
              const visibleStep = (selectedBlock === 1 || selectedBlock === 4) ? 
                [0, 1, 3, 4, 5, 6][index] : 
                index;
              
              return (
                <span
                  key={index}
                  className={`step ${visibleStep === step ? 'current-step' : ''} ${visibleStep <= step ? 'completed' : 'incomplete'}`}
                  onClick={() => handleStepChange(visibleStep, false)}
                >
                  {stepName}
                  {index < (steps.length - (selectedBlock === 1 || selectedBlock === 4 ? 2 : 1)) && (
                    <span className={`separator ${visibleStep <= step ? 'completed' : 'incomplete'}`}> &gt; </span>
                  )}
                </span>
              );
            })}
          </div>


          <div className="button-container">
      {step > 0 && (
              <button className="back-button" onClick={() => handleStepChange((step - 1), true)}>
        <FaArrowLeft /> Back
      </button>

      )}
      <button className="save-button" onClick={handleSave}>
          <FaSave /> Save Campaign
      </button>
          <button 
      className="continue-button" 
      onClick={continueStep} 
      disabled={loading}  
    >
        {loading ? (
          <ThreeDots color="#fff" height={10} width={24} />
        ) : (
          step === steps.length - 1 ? "Submit" : "Continue"
        )}
    </button>

  </div>
</div>
<div className="builder-header">
      <input
              type="text"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Add a name for your research campaign"
              className="campaign-name-input"
          />
        <div className="builder-content">
          {step === 0 && (
            <div className="research-type">
              <h1>Choose the type of research you want to do</h1>
              <div className="research-grid">
                <div className="research-options">
                  {[{
                    id: 1, title: 'Consumer Research', description: 'Understand preferences and behaviors of your target consumers', img: consumerResearchImg
                  }

                //   , {
                //     id: 2, title: 'Ad Testing', description: 'Evaluate the effectiveness of your advertisements', img: adTestingImg
                //   }
                // ].map((block) => (
                //     <div
                //       className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
                //       key={block.id}
                //       onClick={() => setSelectedBlock(block.id)}
                //     >
                //       <img src={block.img} alt={block.title} className="research-image" />
                //       <div className="block-text">
                //         <h2>{block.title}</h2>
                //         <p>{block.description}</p>
                //       </div>
                //     </div>
                //   ))}
                // </div>
                // <div className="research-options">
                //   {[{
                //     id: 3, title: 'Concept Testing', description: 'Test new product ideas and features', img: conceptTestingImg
                //   }
                  
                  , {
                    id: 4, title: 'Consumer Feedback', description: 'Gather feedback on your products or services directly from your customers', img: consumerFeedbackImg
                  }].map((block) => (
                    <div
                      className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
                      key={block.id}
                      onClick={() => setSelectedBlock(block.id)}
                    >
                      <img src={block.img} alt={block.title} className="research-image" />
                      <div className="block-text">
                        <h2>{block.title}</h2>
                        <p>{block.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="objective-step">
              <div className="question-block">
                <label htmlFor="researchObjective">
                  What’s your research objective?
                </label>
                <p className="example-text">
                  Example: "To evaluate the effectiveness and user acceptance of a new AI-powered personal finance app among millennials (ages 25-40) in urban areas, with the goal of improving financial literacy and savings habits. This research aims to understand users' current financial management practices, assess the app's key features such as automated budgeting and investment recommendations, identify potential barriers to adoption, and gauge willingness to pay for premium features. The insights gathered will inform product development, marketing strategies, and potential partnerships with financial institutions, ultimately guiding the app's launch and growth strategy in the competitive fintech market."
                </p>
                <textarea
                id="researchObjective"
                value={researchObjective}
                onChange={handleResearchObjectiveChange}
              />
              </div>
              {/* <div className="question-block">
                <label htmlFor="businessUsage">
                  How will you use this research in your business?
                </label>
                <p className="example-text">
                  Example: "We will use the insights from this research to refine our product development strategy, ensure our new tea blend meets customer preferences, and create targeted marketing campaigns to maximize its launch success."
                </p>
                <textarea
                id="businessUsage"
                value={businessUsage}
                onChange={handleBusinessUsageChange}
              />
              </div> */}
            </div>
          )}
          {step === 2 && (
            <div className="concept-step">
              <div className="concept-block">
                <div className="left-part">
                  <label htmlFor="conceptDescription">
                    Write the concept as you would want to explain it to a respondent
                  </label>
                  <textarea
                id="conceptDescription"
                value={conceptDescription}
                onChange={handleConceptDescriptionChange}
              />
                </div>
                <div className="right-part">
                  <p className="example-text">
                    Example: We're excited to present our latest creation – an all-natural, organic tea blend crafted from the finest ingredients. This blend combines soothing chamomile, refreshing peppermint, and a hint of lemon verbena to create a calming and revitalizing experience. Our goal is to provide a tea that not only tastes great but also offers health benefits such as improved digestion and stress relief.
                  </p>
                </div>
              </div>
              <div className="collateral-upload">
                <label htmlFor="conceptDescription">Add any supporting collaterals </label>
                <div
                  className="upload-section"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleFileDrop}
                  onClick={handleBrowseClick}
                >
                  <div className="upload-icon">📁</div>
                  <div className="upload-text">
                    Drag and drop or browse to choose a file
                  </div>
                  <input
                    type="file"
                    accept="image/*,video/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="questions-step">
              {isGeneratingQuestions ? (
                <div className="loader-container">
                  <ThreeDots color="#6419F7" height={50} width={50} />  
                  <p>Generating questions...</p>
                </div>
              ) : (
                <div className="left-column">
                  <h2>What questions do you want to ask the respondent after showing the concept?</h2>
                  <p className="description">
                    We have generated unbiased and non-leading research standard questions for your concept. Feel free to add and edit as needed. We will still adjust the language if your questions do not meet research standards.
                  </p>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="questions">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {questions && questions.length > 0 && questions.map((question, index) => (
                            <Draggable key={question.id} draggableId={question.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="question-box"
                                >
                                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ flex: 1 }}>
                                      {editIndex === index ? (
                                        <>
                                          <textarea
                                            value={question.question}
                                            onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                            onBlur={() => setEditIndex(null)}
                                            autoFocus
                                          />
                                          <textarea
                                            value={question.followup}
                                            onChange={(e) => handleQuestionChange(index, 'followup', e.target.value)}
                                            onBlur={() => setEditIndex(null)}
                                            placeholder="Follow-up instruction (optional)"
                                            className="followup-input"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>{question.question}</span>
                                          {question.followup && (
                                            <em className="followup-text">
                                              Potential Followup: {question.followup}
                                            </em>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    {selectedBlock !== 1 && ( 
                                      <div className="icons">
                                        <FaEdit className="edit_icon" onClick={() => handleEditQuestion(index)} />
                                        <FaTrashAlt className="del_icon" onClick={() => handleDeleteQuestion(index)} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {selectedBlock !== 1 && (
                      <>
                        <textarea
                          className="new-question-input"
                          value={newQuestion}
                          onChange={(e) => setNewQuestion(e.target.value)}
                          placeholder="Type your question here"
                        />
                        <div className="add-question-container">
                          <button className="add-question-button" onClick={handleAddQuestion}>
                            Add Question
                          </button>
                        </div>
                      </>
                    )}

                </div>
              )}
            </div>
          )}

          {step === 4 && (
            <div className="who-step">
              <h1>Whom do you want to target?</h1>
              <div className='audience-options'> 
                <label>
                  <input
                    type="radio"
                    value="own"
                    checked={audienceOption === 'own'}
                    onChange={() => setAudienceOption('own')}
                  />
                  Target your own audience
                </label>
                <label>
                  <input
                    type="radio"
                    value="recruit"
                    checked={audienceOption === 'recruit'}
                    onChange={() => setAudienceOption('recruit')}
                  />
                  Recruit audience
                </label>
              </div>
              {audienceOption === 'recruit' && (
                <div className="filters-container">
                  <div className="filter">
                    <label>Country</label>
                    <Select
                      isMulti
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      options={countryOptions}
                      placeholder="Search Country"
                      classNamePrefix="custom-select"
                      filterOption={customFilterOption}
                    />
                  </div>
                  <div className="filter">
                    <label>State</label>
                    <Select
                      isMulti
                      value={selectedState}
                      onChange={setSelectedState}
                      options={stateOptions}
                      placeholder="Search State"
                      classNamePrefix="custom-select"
                      filterOption={customFilterOption}
                    />
                  </div>
                  <div className="filter">
                    <label>Education</label>
                    <Select
                      isMulti
                      value={selectedEducation}
                      onChange={setSelectedEducation}
                      options={educationOptions}
                      placeholder="Search Education"
                      classNamePrefix="custom-select"
                      filterOption={customFilterOption}
                    />
                  </div>
                  <div className="filter">
                    <label>Occupation</label>
                    <Select
                      isMulti
                      value={selectedOccupation}
                      onChange={setSelectedOccupation}
                      options={occupationOptions}
                      placeholder="Search Occupation"
                      classNamePrefix="custom-select"
                      filterOption={customFilterOption}
                    />
                  </div>
                  <div className="filter">
                    <label>Income Range</label>
                    <Select
                      isMulti
                      value={selectedIncomeRange}
                      onChange={setSelectedIncomeRange}
                      options={incomeRangeOptions}
                      placeholder="Search Income Range"
                      classNamePrefix="custom-select"
                      filterOption={customFilterOption}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

        {step === 5 && (
          <div className="review-step">
            <h1>Review Your Campaign</h1>
            <div className="review-section">
              <h2>Research Type</h2>
              <div className="review-content">
                {selectedBlock === 1 && 'Consumer Research'}
                {selectedBlock === 2 && 'Ad Testing'}
                {selectedBlock === 3 && 'Concept Testing'}
                {selectedBlock === 4 && 'Consumer Feedback'}
              </div>
            </div>
            <div className="review-section">
              <h2>Research Objective</h2>
              <div className="review-content">{researchObjective}</div>
            </div>
            {selectedBlock !== 1 && selectedBlock !== 4 && (
            <div className="review-section">
              <h2>Concept Description</h2>
              <div className="review-content">{conceptDescription}</div>
            </div>)}
            {/* <div className="review-section">
              <h2>Business Usage</h2>
              <div className="review-content">{businessUsage}</div>
            </div> */}
            <div className="review-section">
              <h2>Questions</h2>
              <div className="review-content">
                {questions.map((q, index) => (
                  <div key={index}>
                    <p>{q.question}</p>
                    {q.followup && (
                      <em className="followup-text">
                        Potential Followup: {q.followup}
                      </em>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="review-section">
              <h2>Target Audience</h2>
              <div className="review-content">
              {audienceOption === 'recruit' ? (
                <>
                  <p>Country: {selectedCountry.map(c => c.label).join(', ')}</p>
                  <p>State: {selectedState.map(s => s.label).join(', ')}</p>
                  <p>Education: {selectedEducation.map(e => e.label).join(', ')}</p>
                  <p>Occupation: {selectedOccupation.map(o => o.label).join(', ')}</p>
                  <p>Income Range: {selectedIncomeRange.map(i => i.label).join(', ')}</p>
                </>
              ) : (
                <p>Audience: Own users</p>
              )}
            </div>

            </div>
          </div>
        )}

          {step === 6 && (
            <div className="test-step">
              <button
                className="test-interview-button"
                onClick={() => window.open(`https://feedback.echovane.com/interviews/${testLink}`, '_blank')}
              >
                Test Interview
              </button>
              <div className='test-interview-instruction'>
              Give the interview a try and make any tweaks to the questions or research objectives if you think something's missing. When you're ready, just hit 'Submit,' and we'll generate a Live link for you to share with your users!
              </div>
            </div>
          )}



        </div>
      </div>
      </div>
  );
};

export default CampaignBuilder;
